






























































































import Vue from "vue";
import Component from "vue-class-component";
import PageTitleHeader from "@/components/shared/PageTitleHeader.vue";
import Spinners from "@/components/utilities/Spinners.vue";

@Component({
    components: {
        Spinners,
        "page-title-header": PageTitleHeader
    }
})
export default class Presenters extends Vue {
    isLoading = true;

    presenter = [
        {
            name: "Nicole Aqui, MD",
            title: "Chief of Transfustion and Apheresis Services",
            organization:
                "Blood & Marrow Collection and Processing Program of the University of Pennsylvania Medical Center",
            track: "Apheresis Presenter",
            img: "",
            bio:
                "Dr. Nicole Aqui is the Chief of Tranfusion and Apheresis Services for the University of Pennsylvania’s Blood & Marrow Collection and Processing Program, Associate Professor of Clinical Pathology and Laboratory Medicine, and Director of the Pathology Residency Training Program. Dr. Aqui is a FACT Inspector of Apheresis Collection. "
        },
        {
            name: "Alex Babic, MD",
            title: "",
            organization: "",
            track: "",
            img: "",
            bio: ""
        },
        {
            name: "Charlie Branson, MS",
            title: "Manager",
            organization:
                "Biologics Production Facility, Nebraska Medicine, The Nebraska Medical Center",
            track: "Apheresis Presenter",
            img: "",
            bio: `<ul>
			<li>Oversees the operation and maintenance of a 20,000 sq. ft. multi-suite cGMP/GTP manufacturing facility with classifications ranging from ISO 8 to ISO 5.</li>
			<li>Manages clinical manufacturing programs for bone marrow/stem cell transplant, cell/gene therapy, and organ/tissue processing (pancreatic islet isolation and transplantation)<li>FACT Processing: Minimal manipulation of HPC,  and More than minimal manipulation of any cell type inspector.</ul>`
        },
        {
            name: "Nicole Aqui, MD",
            title: "",
            organization: "",
            track: "Apheresis Presenter",
            img: "",
            bio: ""
        },
        {
            name: "Nicole Aqui, MD",
            title: "",
            organization: "",
            track: "Apheresis Presenter",
            img: "",
            bio: ""
        },
        {
            name: "Nicole Aqui, MD",
            title: "",
            organization: "",
            track: "Apheresis Presenter",
            img: "",
            bio: ""
        },
        {
            name: "Nicole Aqui, MD",
            title: "",
            organization: "",
            track: "Apheresis Presenter",
            img: "",
            bio: ""
        },
        {
            name: "Nicole Aqui, MD",
            title: "",
            organization: "",
            track: "Apheresis Presenter",
            img: "",
            bio: ""
        },
        {
            name: "Nicole Aqui, MD",
            title: "",
            organization: "",
            track: "Apheresis Presenter",
            img: "",
            bio: ""
        }
    ];
}
